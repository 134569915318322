import globals from '../globals';
export default {
    data() {
        return {
            warning_msg: ''
        }
    },
    methods: { 
        async getSurveyForm(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/survey_forms/?skip=${params.skip}&limit=${params.limit}&search=${params.search}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    console.log(error);
                }
            })
        },
        async getSurveyFormQuestionTypes() {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + "/survey_forms/question/types/dropdown")
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    console.log(error);
                }
            })
        },
        async sendSurveyForm(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.CRM_SALES_URL + `/survey_forms/send`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getSurveyFormById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/survey_forms/${params.form_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    console.log(error);
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getSurveyFormResponseByType(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/survey_forms/answers?form_id=${params.form_id}&skip=${params.skip}&response_type=${params.response_type}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    this.warning_msg = sessionStorage.getItem("error")
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getSurveyFormAnswerById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/survey_forms/answers/${params.question_id}?limit=${params.limit}&skip=${params.skip}&search_key=${params.search_key}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    console.log(error);
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async getSurveyFormSettingsById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/survey_forms/settings/${params}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    console.log(error);
                }
            })
        },
        async updateSurveyFormSettingsById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.CRM_SALES_URL + `/survey_forms/settings/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    console.log(error);
                }
            })
        },
        async createSurveyFormAnswerById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.CRM_SALES_URL + `/survey_forms/answers`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                }
                catch(error){
                    console.log(error);
                }
            })
        },
    }
}