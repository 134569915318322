// Set Global URLs For Production and Testing Based on Release
export default {
  // CRM_URL: "https://crm.digicollect.com",
  CRM_URL: "https://testadminapis.digicollect.com",
  // CRM_SALES_URL: "https://crmsalesapis.digicollect.com",
  CRM_SALES_URL: "https://testcrmsalesapis.digicollect.com",
  // AUTH_SERVICE: "https://adminapis.digicollect.com",
  AUTH_SERVICE: "https://testadminapis.digicollect.com",
  // POS_API: "https://posapismaster.digicollect.com",
  POS_API: "https://testposapis.digicollect.com",
  // SCM_API: "https://scmapis.digicollect.com",
  SCM_API: "https://testscmapis.digicollect.com",
};
